import {createMuiTheme} from "@material-ui/core";

export const darkTheme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            "@global": {
                html: {
                    height: "100%",
                },
                body: {
                    height: "100%",
                },
                "#___gatsby": {
                    height: "100%",
                },
                "#gatsby-focus-wrapper": {
                    height: "100%",
                },
            },
        },
    },
    palette: {
        type: "dark",
        background: {
            default: "#263234",
            paper: "#424242",
        },
        text: {
            primary: "#ffffff",
        },
        primary: {
            main: "#7288c0",
        },
        secondary: {
            main: "#88FFBB",
        },
    },
    typography: {
        fontFamily: [
            "Fira Code",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
})
